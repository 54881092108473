import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { ContentfulWhitelistPageQuery } from "../../../types/gatsby-graphql"
import Container from "../../components/Container/Container"
import Icon from "../../Icon"
import Input from "../../components/Input/Input"

import * as styles from "./Whitelist.module.scss"
import Seo from "../../components/Seo/seo"

const Whitelist = () => {
  const data: ContentfulWhitelistPageQuery = useStaticQuery(graphql`
    query ContentfulWhitelistPage {
      contentfulWhitelistPage {
        title
        subtitle
      }
    }
  `)

  const { register, handleSubmit } = useForm<any>()

  useEffect(() => {
    const main = document.querySelector("main") as unknown as HTMLElement
    main.setAttribute("style", "height: 100%;")
  }, [])

  const onSubmit = useCallback(async (data: { code: string }) => {
    const response = await fetch(
      new URL(`/codes?code=${data.code}`, process.env.GATSBY_API_URL).toString()
    )

    if (response.ok) {
      const code = await response.json()

      switch (code.section.toLowerCase()) {
        case "gallery":
          navigate("/sign-up/gallery/")
          break

        case "artist":
          navigate("/sign-up/artist/")
          break

        case "user":
          navigate("/create-account/")
          break
      }
    } else {
      alert("Invalid code, please try again")
    }
  }, [])

  return (
    <>
      <Seo title="Whitelist" />
      <div className={styles.whitelist}>
        <div className={styles.whitelistInner}>
          <div className={styles.whitelistBackground}>
            <Icon name="purpleSquares" />
          </div>
          <h1>{data.contentfulWhitelistPage?.title}</h1>
          <p>{data.contentfulWhitelistPage?.subtitle}</p>
          <div className={styles.whitelistInput}>
            <Input placeholder="" register={register("code")} />
            <button onClick={handleSubmit(onSubmit)}>Submit</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Whitelist
